import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import "../styles/global/generic.scss";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <section className="hero">
          <hgroup className="hero__content">
            <h1 className="huge-title">Oups, cette page n'existe pas</h1>
            <Link className="link" to="/">Retour à l'accueil</Link>
          </hgroup>
        </section>
      </main>
    </Layout>
  )
}

export default NotFoundPage
